// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the theme object.
$fh5-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$rose-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($fh5-theme);
}

* {
  box-sizing: border-box;
}
// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto Condensed", Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Share";
  src: url(assets/fonts/share.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url(assets/fonts/roboto_condensed.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url(assets/fonts/roboto_condensed_bold.ttf);
  font-display: swap;
  font-weight: bold;
}
